import update from 'immutability-helper';
import { Device } from '../../types';
import { SearchDeviceActionTypes, SearchDeviceActions } from './action';

export interface SearchDeviceState {
  loading: boolean;
  searchDeviceQuery: string;
  searchDropDown: boolean;
  searchDevices: Device[];
  selectedDevice: Device | null;
  error: Error | null;
}

export const initialSearchDeviceState: SearchDeviceState = {
  loading: false,
  searchDeviceQuery: '',
  searchDropDown: false,
  searchDevices: [],
  selectedDevice: null,
  error: null,
};

export default function searchDeviceReducer(state = initialSearchDeviceState, action: SearchDeviceActions) {
  switch (action.type) {
    case SearchDeviceActionTypes.SEARCH_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
        searchDeviceQuery: { $set: action.searchDeviceQuery },
      });
    case SearchDeviceActionTypes.SEARCH_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        searchDevices: { $set: action.searchDevices },
      });
    case SearchDeviceActionTypes.SEARCH_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case SearchDeviceActionTypes.OPEN_SEARCH_DROP_DOWN:
      return update(state, {
        searchDropDown: { $set: true },
      });
    case SearchDeviceActionTypes.CLOSE_SEARCH_DROP_DOWN:
      return update(state, {
        searchDropDown: { $set: false },
      });

    case SearchDeviceActionTypes.SET_IS_LOADING:
      return update(state, {
        loading: { $set: true },
      });
      
    case SearchDeviceActionTypes.ZOOM_TO_DEVICE:
      return update(state, {
        selectedDevice: { $set: action.selectedDevice },
      });
    default:
      return state;
  }
}
