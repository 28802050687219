import Pages from '../../../../helpers/loginPages';

export enum PrimiaryInterfaceActionTypes {
  OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL',
  CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL',

  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  GO_TO_PREVIOUS_PAGE = 'GO_TO_PREVIOUS_PAGE',

  OPEN_LOG_OUT_MODAL = 'OPEN_LOG_OUT_MODAL',
  CLOSE_LOG_OUT_MODAL = 'CLOSE_LOG_OUT_MODAL',
}

export interface OpenAuthModal {
  type: PrimiaryInterfaceActionTypes.OPEN_AUTH_MODAL;
}

export interface CloseAuthModal {
  type: PrimiaryInterfaceActionTypes.CLOSE_AUTH_MODAL;
}

export interface SetCurrentPage {
  type: PrimiaryInterfaceActionTypes.SET_CURRENT_PAGE;
  stage: Pages;
}

export interface GoToPreviousPage {
  type: PrimiaryInterfaceActionTypes.GO_TO_PREVIOUS_PAGE;
}

export interface OpenLogOutModal {
  type: PrimiaryInterfaceActionTypes.OPEN_LOG_OUT_MODAL;
}

export interface CloseLogOutModal {
  type: PrimiaryInterfaceActionTypes.CLOSE_LOG_OUT_MODAL;
}

export function openAuthModal(): OpenAuthModal {
  return {
    type: PrimiaryInterfaceActionTypes.OPEN_AUTH_MODAL,
  };
}

export function closeAuthModal(): CloseAuthModal {
  return {
    type: PrimiaryInterfaceActionTypes.CLOSE_AUTH_MODAL,
  };
}

export function setCurrentPage(stage: Pages): SetCurrentPage {
  return {
    type: PrimiaryInterfaceActionTypes.SET_CURRENT_PAGE,
    stage,
  };
}
export function goToPreviousPage(): GoToPreviousPage {
  return {
    type: PrimiaryInterfaceActionTypes.GO_TO_PREVIOUS_PAGE,
  };
}

export function openLogOutModal(): OpenLogOutModal {
  return {
    type: PrimiaryInterfaceActionTypes.OPEN_LOG_OUT_MODAL,
  };
}

export function closelogOutModal(): CloseLogOutModal {
  return {
    type: PrimiaryInterfaceActionTypes.CLOSE_LOG_OUT_MODAL,
  };
}

export type PrimiaryInterfaceActions =
  | OpenAuthModal
  | CloseAuthModal
  | SetCurrentPage
  | GoToPreviousPage
  | OpenLogOutModal
  | CloseLogOutModal;
