import { filter, Observable, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { getDeviceQuery } from '../query';
import { GetDeviceActions, GetDeviceActionTypes, GetDeviceRequest, getDeviceSuccess, getDeviceError } from './action';
import { AppActions } from '../../../../helpers/appActionTypes';

export function getDeviceEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: GetDeviceActions) => {
      return action.type === GetDeviceActionTypes.GET_DEVICE_REQUEST;
    }),
    switchMap(({ getDeviceId }: GetDeviceRequest) =>
      getDevice(getDeviceId).then(getDeviceSuccess).catch(getDeviceError),
    ),
  );
}

export async function getDevice(input) {
  const graphQLClient = await setAuthenticatedClient();
  const {
    data: { getDevice },
  } = await graphQLClient.query({
    query: getDeviceQuery,
    variables: { input: { id: input } },
  });

  return getDevice;
}
