import update from 'immutability-helper';
import { NotificationActionTypes, NotificationActions } from './action';

export interface NotificationState {
  isNotification: boolean;
  notificationData: {
    notificationBg: string;
    notificationColors: string;
    notificationMessage: string;
    notificationIcon: string;
  };
}

export const initialNotificationState: NotificationState = {
  isNotification: false,
  notificationData: null,
};

export default function notificationReducer(state = initialNotificationState, action: NotificationActions) {
  switch (action.type) {
    case NotificationActionTypes.SHOW_NOTIFICATION:
      return update(state, {
        isNotification: { $set: true },
        notificationData: { $set: action.notificationData },
      });
    case NotificationActionTypes.CLOSE_NOTIFICATION:
      return update(state, {
        isNotification: { $set: false },
        notificationData: { $set: null },
      });
    default:
      return state;
  }
}
