import { GetUserActionTypes, GetUserActions } from './action';
import update from 'immutability-helper';

export enum UserType {
  USER = 'USER',
}

export interface GetUserState {
  loading: boolean;
  userId: string;
  userError: null | Error;
  userData: {
    avatar: string;
    email: string;
    groupName: string;
    id: string;
    joinDate: number;
    phoneNumber: string;
    point: number;
    status: string;
    username: string;
    __typename: UserType;
  };
  getUserSuccess: boolean;
}

export const initialGetUserState: GetUserState = {
  loading: false,
  userError: null,
  userData: null,
  getUserSuccess: false,
  userId: '',
};

export default function getUserReducer(state = initialGetUserState, action: GetUserActions) {
  switch (action.type) {
    case GetUserActionTypes.GET_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
        userId: { $set: action.userId },
      });
    case GetUserActionTypes.GET_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        userData: { $set: action.userData },
        getUserSuccess: { $set: true },
      });
    case GetUserActionTypes.GET_USER_ERROR:
      return update(state, {
        loading: { $set: false },
        userError: { $set: action.userError },
      });
    default:
      return state;
  }
}
