import { gql } from '@apollo/client';

export const updateDeviceMutation = gql`
  mutation updateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      ... on Terminal {
        address
        approvalsCount
        id
        location {
          lat
          lon
        }
        notes
        organization
        registrationDate
        status
        user {
          id
          username
        }
        __typename: __typename
      }
    }
  }
`;