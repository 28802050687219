import update from 'immutability-helper';
import { CreateDeviceActionTypes, CreateDeviceActions } from './action';
import { DeviceType } from '../../types';

enum DeviceStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export interface CreateDeviceState {
  loading: boolean;
  notes: string;
  organization: string;
  input: {
    address: string;
    deviceType: DeviceType.TERMINAL;
    location: {
      lat: number;
      lon: number;
    };
    notes: string;
    organization: string;
    userLocation: {
      lat: number;
      lon: number;
    };
  };
  createDeviceData: {
    address: string;
    approvalsCount: number;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    organization: string;
    notes: string;
    registrationDate: string;
    status: DeviceStatus;
    user: {
      id: string;
    };
  } | null;
  createDeviceError: Error;
  createDeviceModal: boolean;
  createDeviceSuccess: boolean;
}

export const initialCreateDeviceState: CreateDeviceState = {
  loading: false,
  notes: '',
  organization: '',
  createDeviceData: null,
  createDeviceError: null,
  createDeviceModal: false,
  createDeviceSuccess: false,
  input: null,
};

export default function createDeviceReducer(state = initialCreateDeviceState, action: CreateDeviceActions) {
  switch (action.type) {
    case CreateDeviceActionTypes.CREATE_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
        input: { $set: action.input },
      });
    case CreateDeviceActionTypes.CREATE_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        createDeviceData: { $set: action.createDeviceData },
        createDeviceModal: { $set: false },
        createDeviceSuccess: { $set: true },
      });
    case CreateDeviceActionTypes.CREATE_DEVICE_ERROR:
      return update(state, {
        createDeviceError: { $set: action.error },
      });

    case CreateDeviceActionTypes.NOTES:
      return update(state, {
        notes: { $set: action.notes },
      });
    case CreateDeviceActionTypes.ORGANIZATION:
      return update(state, {
        organization: { $set: action.organization },
      });

    case CreateDeviceActionTypes.OPEN_CREATE_DEVICE_MODAL:
      return update(state, {
        createDeviceModal: { $set: true },
      });
    case CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_MODAL:
      return update(state, {
        createDeviceModal: { $set: false },
      });
      
    case CreateDeviceActionTypes.DEFAULT_CREATE_DEVICE_STATE:
      return state;
    default:
      return state;
  }
}
