import update from 'immutability-helper';
import { Device } from '../../types';
import { ListDevicesActionTypes, ListDevicesActions } from './action';

export interface ListDevicesState {
  loading: boolean;
  error: null | Error;
  devices: Device[];
  total: number;
  listDevicesSuccess: boolean;
}

export const initialListDevicesState: ListDevicesState = {
  loading: false,
  error: undefined,
  devices: [],
  total: 0,
  listDevicesSuccess: false,
};

export default function listDevicesReducer(state = initialListDevicesState, action: ListDevicesActions) {
  switch (action.type) {
    case ListDevicesActionTypes.LIST_DEVICES_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case ListDevicesActionTypes.LIST_DEVICES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        devices: { $set: action.deviceFilter.devices },
        total: { $set: action.deviceFilter.total },
      });
    case ListDevicesActionTypes.LIST_DEVICES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}
