import { DeviceType } from '../../types';

export enum ApproveDeviceActionType {
  APPROVE_DEVICE_REQUEST = 'APPROVE_DEVICE_REQUEST',
  APPROVE_DEVICE_SUCCESS = 'APPROVE_DEVICE_SUCCESS',
  APPROVE_DEVICE_ERROR = 'APPROVE_DEVICE_ERROR',
}

export interface ApproveDeviceResponseData {
  id: string;
  __typename: DeviceType;
  address: string;
  approvalsCount: number;
  notes: string;
  registrationDate: number;
  status: string;
  location: {
    lat: number;
    lon: number;
  };
  user: {
    id: string;
  };
}

export interface ApproveDeviceRequest {
  type: ApproveDeviceActionType.APPROVE_DEVICE_REQUEST;
  approveDeviceId: string;
  userLoc: {
    lat: number;
    lon: number;
  };
}

export interface ApproveDeviceSuccess {
  type: ApproveDeviceActionType.APPROVE_DEVICE_SUCCESS;
  approveDeviceSuccessData: ApproveDeviceResponseData;
}

export interface ApproveDeviceError {
  type: ApproveDeviceActionType.APPROVE_DEVICE_ERROR;
  error: Error;
}

export type ApproveDeviceActions = ApproveDeviceRequest | ApproveDeviceSuccess | ApproveDeviceError;

export function approveDeviceRequest(
  approveDeviceId: string,
  userLoc: {
    lat: number;
    lon: number;
  },
): ApproveDeviceRequest {
  return {
    type: ApproveDeviceActionType.APPROVE_DEVICE_REQUEST,
    approveDeviceId,
    userLoc,
  };
}

export function approveDeviceSuccess(approveDeviceSuccessData: ApproveDeviceResponseData): ApproveDeviceSuccess {
  return {
    type: ApproveDeviceActionType.APPROVE_DEVICE_SUCCESS,
    approveDeviceSuccessData,
  };
}

export function approveDeviceError(error: Error): ApproveDeviceError {
  return {
    type: ApproveDeviceActionType.APPROVE_DEVICE_ERROR,
    error,
  };
}
