import update from 'immutability-helper';
import { ApproveDeviceActionType, ApproveDeviceActions, ApproveDeviceResponseData } from './action';

export interface ApproveDeviceState {
  approveDeviceLoading: boolean;
  approveDeviceId: string;
  userLoc: {
    lat: number;
    lon: number;
  };
  approveDeviceSuccessData: ApproveDeviceResponseData;
  approveDeviceSuccess: boolean;
  approveDeviceError: Error | null;
}

export const initialApproveDeviceState: ApproveDeviceState = {
  approveDeviceLoading: false,
  approveDeviceId: '',
  userLoc: null,
  approveDeviceSuccessData: null,
  approveDeviceSuccess: false,
  approveDeviceError: null,
};

export default function approveDeviceReducer(state = initialApproveDeviceState, action: ApproveDeviceActions) {
  switch (action.type) {
    case ApproveDeviceActionType.APPROVE_DEVICE_REQUEST:
      return update(state, {
        approveDeviceLoading: { $set: true },
        approveDeviceId: { $set: action.approveDeviceId },
        userLoc: { $set: action.userLoc },
      });
    case ApproveDeviceActionType.APPROVE_DEVICE_SUCCESS:
      return update(state, {
        approveDeviceLoading: { $set: false },
        approveDeviceSuccessData: { $set: action.approveDeviceSuccessData },
        approveDeviceSuccess: { $set: true },
      });
    case ApproveDeviceActionType.APPROVE_DEVICE_ERROR:
      return update(state, {
        approveDeviceLoading: { $set: false },
        approveDeviceError: { $set: action.error },
      });
    default:
      return state;
  }
}
