import { DeviceType } from '../../types';

export enum UpdateDeviceActionTypes {
  UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST',
  UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS',
  UPDATE_DEVICE_ERROR = 'UPDATE_DEVICE_ERROR',

  OPEN_UPDATE_DEVICE_MODAL = 'OPEN_UPDATE_DEVICE_MODAL',
  CLOSE_UPDATE_DEVICE_MODAL = 'CLOSE_UPDATE_DEVICE_MODAL',

  UPDATE_DEVICE_DEFAULT_STATE = 'UPDATE_DEVICE_DEFAULT_STATE',
}

export interface UpdateDeviceRequest {
  type: UpdateDeviceActionTypes.UPDATE_DEVICE_REQUEST;
  updateDeviceRequestFields: {
    address: string;
    deviceType: DeviceType;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    notes: string;
    organization: string;
  };
}

export interface UpdateDeviceSuccess {
  type: UpdateDeviceActionTypes.UPDATE_DEVICE_SUCCESS;
  updateDeviceSuccessFields: {
    address: string;
    deviceType: DeviceType;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    notes: string;
    organization: string;
  };
}

export interface UpdateDeviceError {
  type: UpdateDeviceActionTypes.UPDATE_DEVICE_ERROR;
  error: Error;
}

export interface OpenUpdateDeviceModal {
  type: UpdateDeviceActionTypes.OPEN_UPDATE_DEVICE_MODAL;
}

export interface CloseUpdateDeviceModal {
  type: UpdateDeviceActionTypes.CLOSE_UPDATE_DEVICE_MODAL;
}

export interface UpdateDeviceDefaultState {
  type: UpdateDeviceActionTypes.UPDATE_DEVICE_DEFAULT_STATE;
}

export type UpdateDeviceActions =
  | UpdateDeviceRequest
  | UpdateDeviceSuccess
  | UpdateDeviceError
  | OpenUpdateDeviceModal
  | CloseUpdateDeviceModal
  | UpdateDeviceDefaultState;

export function updateDeviceRequest(updateDeviceRequestFields: {
  address: string;
  deviceType: DeviceType;
  id: string;
  location: {
    lat: number;
    lon: number;
  };
  notes: string;
  organization: string;
}): UpdateDeviceRequest {
  return {
    type: UpdateDeviceActionTypes.UPDATE_DEVICE_REQUEST,
    updateDeviceRequestFields,
  };
}

export function updateDeviceSuccess(updateDeviceSuccessFields: {
  address: string;
  deviceType: DeviceType;
  id: string;
  location: {
    lat: number;
    lon: number;
  };
  organization: string;
  notes: string;
}): UpdateDeviceSuccess {
  return {
    type: UpdateDeviceActionTypes.UPDATE_DEVICE_SUCCESS,
    updateDeviceSuccessFields,
  };
}

export function updateDeviceError(error: Error): UpdateDeviceError {
  return {
    type: UpdateDeviceActionTypes.UPDATE_DEVICE_ERROR,
    error,
  };
}

export function openUpdateDeviceModal(): OpenUpdateDeviceModal {
  return {
    type: UpdateDeviceActionTypes.OPEN_UPDATE_DEVICE_MODAL,
  };
}

export function closeUpdateDeviceModal(): CloseUpdateDeviceModal {
  return {
    type: UpdateDeviceActionTypes.CLOSE_UPDATE_DEVICE_MODAL,
  };
}

export function updateDeviceDefaultState(): UpdateDeviceDefaultState {
  return {
    type: UpdateDeviceActionTypes.UPDATE_DEVICE_DEFAULT_STATE,
  };
}
