import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { getSession } from '../../../../client/graphql';
import { InitialClientActions, InitialClientActionTypes, initialClientError, initialClientSuccess } from './action';
import { AppActions } from '../../../../helpers/appActionTypes';

export function initialClientEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: InitialClientActions) => action.type === InitialClientActionTypes.INITIAL_CLIENT_REQUEST),
    switchMap(({}) => getSession().then(initialClientSuccess).catch(initialClientError)),
  );
}
