import { Observable } from 'redux';
import { filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { getUserQuery } from '../query';
import { GetUserActionTypes, GetUserRequest, getUserError, getUserSuccess } from './action';
import { AppActions } from '../../../../helpers/reduxImports/actionsIndex';

export function getUserEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: AppActions) => {
      return action.type === GetUserActionTypes.GET_USER_REQUEST;
    }),
    switchMap(({ userId }: GetUserRequest) => getUser(userId).then(getUserSuccess).catch(getUserError)),
  );
}

export async function getUser(input) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { getUser: user },
  } = await graphQLClient.query({
    query: getUserQuery,
    variables: { input: { id: input } },
  });

  return user;
}
