import { Device } from '../../types';

export enum ListDevicesActionTypes {
  LIST_DEVICES_REQUEST = 'LIST_DEVICES_REQUEST',
  LIST_DEVICES_SUCCESS = 'LIST_DEVICES_SUCCESS',
  LIST_DEVICES_ERROR = 'LIST_DEVICES_ERROR',
}

export interface DeviceFilter {
  devices: Device[];
  total: number;
}

export interface ListDevicesRequest {
  type: ListDevicesActionTypes.LIST_DEVICES_REQUEST;
  deviceFilter: DeviceFilter;
}

export interface ListDevicesSuccess {
  type: ListDevicesActionTypes.LIST_DEVICES_SUCCESS;
  deviceFilter: DeviceFilter;
}

export interface ListDevicesError {
  type: ListDevicesActionTypes.LIST_DEVICES_ERROR;
  error: Error;
}

export type ListDevicesActions = ListDevicesRequest | ListDevicesSuccess | ListDevicesError;

export function listDevicesRequest(deviceFilter: DeviceFilter): ListDevicesRequest {
  return {
    type: ListDevicesActionTypes.LIST_DEVICES_REQUEST,
    deviceFilter,
  };
}

export function listDevicesSuccess(deviceFilter: DeviceFilter): ListDevicesSuccess {
  return {
    type: ListDevicesActionTypes.LIST_DEVICES_SUCCESS,
    deviceFilter,
  };
}

export function listDevicesError(error: Error): ListDevicesError {
  return {
    type: ListDevicesActionTypes.LIST_DEVICES_ERROR,
    error,
  };
}
