export enum GetUserActionTypes {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR',
}

export interface GetUserRequest {
  type: GetUserActionTypes.GET_USER_REQUEST;
  userId: string;
}

export interface GetUserSuccess {
  type: GetUserActionTypes.GET_USER_SUCCESS;
  userData: any;
}

export interface GetUserError {
  type: GetUserActionTypes.GET_USER_ERROR;
  userError: Error;
}

export type GetUserActions = GetUserRequest | GetUserSuccess | GetUserError;

export function getUserRequest(userId: string): GetUserRequest {
  return {
    type: GetUserActionTypes.GET_USER_REQUEST,
    userId,
  };
}

export function getUserSuccess(userData: any): GetUserSuccess {
  return {
    type: GetUserActionTypes.GET_USER_SUCCESS,
    userData,
  };
}

export function getUserError(userError: Error): GetUserError {
  return {
    type: GetUserActionTypes.GET_USER_ERROR,
    userError,
  };
}
