import update from 'immutability-helper';
import { User } from '../../types';
import { ListUserActions, ListUsersActionTypes } from './action';

export interface ListUserState {
  loading: boolean;
  users: User[];
  total: number;
  error: Error | null;
  leaderBoard: boolean;
}

export const initialListUserState: ListUserState = {
  loading: false,
  users: [],
  total: 0,
  error: null,
  leaderBoard: false,
};

export default function listUserReducer(state = initialListUserState, action: ListUserActions) {
  switch (action.type) {
    case ListUsersActionTypes.LIST_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case ListUsersActionTypes.LIST_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        users: { $set: action.userFilter.users },
        total: { $set: action.userFilter.total },
      });

    case ListUsersActionTypes.LIST_USER_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListUsersActionTypes.OPEN_LEADER_BOARD:
      return update(state, {
        leaderBoard: { $set: true },
      });

    case ListUsersActionTypes.CLOSE_LEADER_BOARD:
      return update(state, {
        leaderBoard: { $set: false },
      });

    default:
      return state;
  }
}
