import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { approveDeviceMutation } from '../query';
import { AppActions } from '../../../../helpers/appActionTypes';
import {
  ApproveDeviceActionType,
  ApproveDeviceActions,
  ApproveDeviceRequest,
  approveDeviceSuccess,
  approveDeviceError,
} from './action';

export function approveDeviceEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: ApproveDeviceActions) => action.type === ApproveDeviceActionType.APPROVE_DEVICE_REQUEST),
    switchMap(({ approveDeviceId, userLoc }: ApproveDeviceRequest) =>
      approveDevice(approveDeviceId, userLoc).then(approveDeviceSuccess).catch(approveDeviceError),
    ),
  );
}

async function approveDevice(id: string, userLoc: { lat: number; lon: number }) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { approveDevice: device },
  } = await graphQLClient.mutate({
    mutation: approveDeviceMutation,
    variables: { input: { id, location: userLoc } },
  });

  return device;
}
