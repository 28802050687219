import { Observable } from 'redux';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { deleteDeviceMutation } from '../query';
import { filter, switchMap } from 'rxjs';
import {
  DeleteDeviceActionTypes,
  DeleteDeviceActions,
  DeleteDeviceRequest,
  deleteDeviceSuccess,
  deleteDeviceError,
} from './action';

export function deleteDeviceEpic(action$): Observable<DeleteDeviceActions> {
  return action$.pipe(
    filter((action: DeleteDeviceActions) => action.type === DeleteDeviceActionTypes.DELETE_DEVICE_REQUEST),
    switchMap(({ deleteDeviceId }: DeleteDeviceRequest) =>
      deleteDevice(deleteDeviceId).then(deleteDeviceSuccess).catch(deleteDeviceError),
    ),
  );
}

export async function deleteDevice(id) {
  try {
    const graphQLClient = await setAuthenticatedClient();

    const response = await graphQLClient.mutate({
      mutation: deleteDeviceMutation,
      variables: { input: { id } },
    });

    const device = response.data.deleteDevice;
    return device;
  } catch (error) {
    throw error;
  }
}
