import update from 'immutability-helper';
import { MapLocationActionsTypes, MapLocationActions } from './action';

export interface MapLocationState {
  userLocation: { lat: number; lon: number };
  deviceLocation: {
    coordinats: {
      lat: number;
      lon: number;
    };
    address: object;
  };
  isActiveMarker: boolean;
  activeDeviceId: string;
  isMapCart: boolean;
  userLocError: string;
  deviceLocError: string;
}

export const initialMapLocationState: MapLocationState = {
  userLocation: null,
  deviceLocation: null,
  isActiveMarker: false,
  activeDeviceId: null,
  isMapCart: false,
  userLocError: null,
  deviceLocError: null,
};

export default function mapLocationReducer(state = initialMapLocationState, action: MapLocationActions) {
  switch (action.type) {
    case MapLocationActionsTypes.SET_SAVE_USER_LOCATION:
      return update(state, {
        userLocation: { $set: action.userLocation },
      });
    case MapLocationActionsTypes.SET_USER_LOCATION_ERROR:
      return update(state, {
        userLocError: { $set: action.userLocError },
      });
    case MapLocationActionsTypes.SET_SAVE_DEVICE_LOCATION:
      return update(state, {
        deviceLocation: { $set: action.deviceLocation },
      });
    case MapLocationActionsTypes.SET_DEVICE_LOCATION_ERROR:
      return update(state, {
        deviceLocError: { $set: action.deviceLocError },
      });
    case MapLocationActionsTypes.OPEN_MAP_CART:
      return update(state, {
        isMapCart: { $set: true },
      });
    case MapLocationActionsTypes.CLOSE_MAP_CART:
      return update(state, {
        isMapCart: { $set: false },
      });
    case MapLocationActionsTypes.SET_ACTIVE_MARKER:
      return update(state, {
        isActiveMarker: { $set: action.isActiveMarker },
        activeDeviceId: { $set: action.activeDeviceId },
      });
    default:
      return state;
  }
}
