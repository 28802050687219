import { DeviceType } from '../../types';

export enum CreateDeviceActionTypes {
  CREATE_DEVICE_REQUEST = 'CREATE_DEVICE_REQUEST',
  CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS',
  CREATE_DEVICE_ERROR = 'CREATE_DEVICE_ERROR',

  NOTES = 'NOTES',
  ORGANIZATION = 'ORGANIZATION',

  OPEN_CREATE_DEVICE_MODAL = 'OPEN_CREATE_DEVICE_MODAL',
  CLOSE_CREATE_DEVICE_MODAL = 'CLOSE_CREATE_DEVICE_MODAL',

  DEFAULT_CREATE_DEVICE_STATE = 'DEFAULT_CREATE_DEVICE_STATE',
}

enum DeviceStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

interface CreateDeviceRequestData {
  address: string;
  deviceType: DeviceType.TERMINAL;
  location: {
    lat: number;
    lon: number;
  };
  notes: string;
  organization: string;
  userLocation: {
    lat: number;
    lon: number;
  };
}

interface CreateDeviceResponseData {
  address: string;
  approvalsCount: number;
  id: string;
  location: {
    lat: number;
    lon: number;
  };
  organization: string;
  notes: string;
  registrationDate: string;
  status: DeviceStatus;
  user: {
    id: string;
  };
}

export interface CreateDeviceRequest {
  type: CreateDeviceActionTypes.CREATE_DEVICE_REQUEST;
  input: CreateDeviceRequestData;
}

export interface CreateDeviceSuccess {
  type: CreateDeviceActionTypes.CREATE_DEVICE_SUCCESS;
  createDeviceData: CreateDeviceResponseData;
}

export interface CreateDeviceError {
  type: CreateDeviceActionTypes.CREATE_DEVICE_ERROR;
  error: Error;
}

export interface Notes {
  type: CreateDeviceActionTypes.NOTES;
  notes: string;
}

export interface Organization {
  type: CreateDeviceActionTypes.ORGANIZATION;
  organization: string;
}

export interface OpenCreateDeviceModal {
  type: CreateDeviceActionTypes.OPEN_CREATE_DEVICE_MODAL;
}

export interface CloseCreateDeviceModal {
  type: CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_MODAL;
}

export interface DefaultCreateDeviceState {
  type: CreateDeviceActionTypes.DEFAULT_CREATE_DEVICE_STATE;
}

export type CreateDeviceActions =
  | CreateDeviceRequest
  | CreateDeviceSuccess
  | CreateDeviceError
  | Notes
  | Organization
  | OpenCreateDeviceModal
  | CloseCreateDeviceModal
  | DefaultCreateDeviceState;

export function createDeviceRequest(input: any): CreateDeviceRequest {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICE_REQUEST,
    input,
  };
}

export function createDeviceSuccess(createDeviceData: any): CreateDeviceSuccess {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICE_SUCCESS,
    createDeviceData,
  };
}

export function createDeviceError(error: Error): CreateDeviceError {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICE_ERROR,
    error,
  };
}

export function createDeviceNotes(notes: string): Notes {
  return {
    type: CreateDeviceActionTypes.NOTES,
    notes,
  };
}

export function createDeviceOrganization(organization: string): Organization {
  return {
    type: CreateDeviceActionTypes.ORGANIZATION,
    organization,
  };
}

export function openCreateDeviceModal(): OpenCreateDeviceModal {
  return {
    type: CreateDeviceActionTypes.OPEN_CREATE_DEVICE_MODAL,
  };
}

export function closeCreateDeviceModal(): CloseCreateDeviceModal {
  return {
    type: CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_MODAL,
  };
}

export function defaultCreateDeviceState(): DefaultCreateDeviceState {
  return {
    type: CreateDeviceActionTypes.DEFAULT_CREATE_DEVICE_STATE,
  };
}
