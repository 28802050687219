import update from 'immutability-helper';
import { DeleteDeviceActionTypes, DeleteDeviceActions } from './action';

export interface DeleteDeviceState {
  deleteDeviceLoading: boolean;
  deleteDeviceId: string;
  responseMassage: string;
  deleteDeviceSuccess: boolean;
  error: Error;
}

export const initialDeleteDeviceState: DeleteDeviceState = {
  deleteDeviceLoading: false,
  deleteDeviceId: '',
  responseMassage: null,
  deleteDeviceSuccess: false,
  error: null,
};

export default function deleteDeviceReducer(state = initialDeleteDeviceState, action: DeleteDeviceActions) {
  switch (action.type) {
    case DeleteDeviceActionTypes.DELETE_DEVICE_REQUEST:
      return update(state, {
        deleteDeviceLoading: { $set: true },
        deleteDeviceId: { $set: action.deleteDeviceId },
      });
    case DeleteDeviceActionTypes.DELETE_DEVICE_SUCCESS:
      return update(state, {
        deleteDeviceLoading: { $set: false },
        responseMassage: { $set: action.responseMassage },
        deleteDeviceSuccess: { $set: true },
      });
    case DeleteDeviceActionTypes.DELETE_DEVICE_ERROR:
      return update(state, {
        deleteDeviceLoading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}
