export enum GetDeviceActionTypes {
  GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST',
  GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS',
  GET_DEVICE_ERROR = 'GET_DEVICE_ERROR',

  OPEN_GET_DEVICE_MODAL = 'OPEN_GET_DEVICE_MODAL',
  CLOSE_GET_DEVICE_MODAL = 'CLOSE_GET_DEVICE_MODAL',
}

enum DeviceStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

interface GetDeviceResponseInput {
  address: string;
  approvalsCount: number;
  id: string;
  location: {
    lat: number;
    lon: number;
  };
  notes: string;
  registrationDate: string;
  organization: string;
  status: DeviceStatus;
  user: {
    id: string;
  };
}

export interface GetDeviceRequest {
  type: GetDeviceActionTypes.GET_DEVICE_REQUEST;
  getDeviceId: string;
}

export interface GetDeviceSuccess {
  type: GetDeviceActionTypes.GET_DEVICE_SUCCESS;
  getDeviceData: GetDeviceResponseInput;
}

export interface GetDeviceError {
  type: GetDeviceActionTypes.GET_DEVICE_ERROR;
  error: Error;
}

export interface OpenGetDeviceModal {
  type: GetDeviceActionTypes.OPEN_GET_DEVICE_MODAL;
}

export interface CloseGetDeviceModal {
  type: GetDeviceActionTypes.CLOSE_GET_DEVICE_MODAL;
}

export type GetDeviceActions =
  | GetDeviceRequest
  | GetDeviceSuccess
  | GetDeviceError
  | OpenGetDeviceModal
  | CloseGetDeviceModal;

export function getDeviceRequest(getDeviceId: string): GetDeviceRequest {
  return {
    type: GetDeviceActionTypes.GET_DEVICE_REQUEST,
    getDeviceId,
  };
}

export function getDeviceSuccess(getDeviceData: any): GetDeviceSuccess {
  return {
    type: GetDeviceActionTypes.GET_DEVICE_SUCCESS,
    getDeviceData,
  };
}

export function getDeviceError(error: Error): GetDeviceError {
  return {
    type: GetDeviceActionTypes.GET_DEVICE_ERROR,
    error,
  };
}

export function openGetDeviceModal(): OpenGetDeviceModal {
  return {
    type: GetDeviceActionTypes.OPEN_GET_DEVICE_MODAL,
  };
}

export function closeGetDeviceModal(): CloseGetDeviceModal {
  return {
    type: GetDeviceActionTypes.CLOSE_GET_DEVICE_MODAL,
  };
}
