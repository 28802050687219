import { Observable } from 'redux';
import { filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { updateDeviceMutation } from '../query';
import { AppActions } from '../../../../helpers/appActionTypes';
import {
  UpdateDeviceActionTypes,
  UpdateDeviceActions,
  UpdateDeviceRequest,
  updateDeviceSuccess,
  updateDeviceError,
} from './action';

export function updateDeviceEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: UpdateDeviceActions) => action.type === UpdateDeviceActionTypes.UPDATE_DEVICE_REQUEST),
    switchMap(({ updateDeviceRequestFields }: UpdateDeviceRequest) =>
      updateDevice(updateDeviceRequestFields).then(updateDeviceSuccess).catch(updateDeviceError),
    ),
  );
}

export async function updateDevice(updateDeviceRequestFields: any) {
  const graphQLClient = await setAuthenticatedClient();

  const response = await graphQLClient.mutate({
    mutation: updateDeviceMutation,
    variables: { input: updateDeviceRequestFields },
  });

  return response.data.updateDevice;
}
