export enum DeleteDeviceActionTypes {
  DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR',
}

export interface DeleteDeviceRequest {
  type: DeleteDeviceActionTypes.DELETE_DEVICE_REQUEST;
  deleteDeviceId: string;
}

export interface DeleteDeviceSuccess {
  type: DeleteDeviceActionTypes.DELETE_DEVICE_SUCCESS;
  responseMassage: string;
}

export interface DeleteDeviceError {
  type: DeleteDeviceActionTypes.DELETE_DEVICE_ERROR;
  error: Error;
}

export type DeleteDeviceActions = DeleteDeviceRequest | DeleteDeviceSuccess | DeleteDeviceError;

export function deleteDeviceRequest(deleteDeviceId: string): DeleteDeviceRequest {
  return {
    type: DeleteDeviceActionTypes.DELETE_DEVICE_REQUEST,
    deleteDeviceId,
  };
}

export function deleteDeviceSuccess(responseMassage: any): DeleteDeviceSuccess {
  return {
    type: DeleteDeviceActionTypes.DELETE_DEVICE_SUCCESS,
    responseMassage,
  };
}

export function deleteDeviceError(error: Error): DeleteDeviceError {
  return {
    type: DeleteDeviceActionTypes.DELETE_DEVICE_ERROR,
    error,
  };
}
