import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { createDeviceMutation } from '../query';
import { AppActions } from '../../../../helpers/appActionTypes';
import { CreateDeviceActionTypes, CreateDeviceRequest, createDeviceSuccess, createDeviceError } from './action';

export function createDeviceEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: AppActions) => action.type === CreateDeviceActionTypes.CREATE_DEVICE_REQUEST),
    switchMap(({ input }: CreateDeviceRequest) =>
      createDevice(input).then(createDeviceSuccess).catch(createDeviceError),
    ),
  );
}

export async function createDevice(input) {
  const graphQLClient = await setAuthenticatedClient();
  const {
    data: { createDevice: device },
  } = await graphQLClient.mutate({
    mutation: createDeviceMutation,
    variables: { input },
  });

  return device;
}
