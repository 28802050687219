export enum NotificationActionTypes {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
}

export interface ShowNotification {
  type: NotificationActionTypes.SHOW_NOTIFICATION;
  notificationData: {
    notificationBg: string;
    notificationColors: string;
    notificationMessage: string;
    notificationIcon: string;
  };
}

export interface CloseNotification {
  type: NotificationActionTypes.CLOSE_NOTIFICATION;
  notificationData: {
    notificationBg: string;
    notificationColors: string;
    notificationMessage: string;
    notificationIcon: string;
  };
}

export type NotificationActions = ShowNotification | CloseNotification;

export function showNotification(notificationData: {
  notificationBg: string;
  notificationColors: string;
  notificationMessage: string;
  notificationIcon: string;
}): ShowNotification {
  return {
    type: NotificationActionTypes.SHOW_NOTIFICATION,
    notificationData,
  };
}

export function closeNotification(notificationData: {
  notificationBg: string;
  notificationColors: string;
  notificationMessage: string;
  notificationIcon: string;
}): CloseNotification {
  return {
    type: NotificationActionTypes.CLOSE_NOTIFICATION,
    notificationData,
  };
}
