import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import primiaryInterfaceReducer from '../components/workspace/primaryInterface/redux/reducer';
import singInreducer from '../components/workspace/authComponents/singIn/redux/reducer';
import { singInEpic } from '../components/workspace/authComponents/singIn/redux/epic';
import registrationReducer from '../components/workspace/authComponents/registration/redux/reducer';
import {
  registrationCodeConfirmationEpic,
  registrationCridentialsEpic,
} from '../components/workspace/authComponents/registration/redux/epic';
import passwordResetReducer from '../components/workspace/authComponents/resetPassword/redux/reducer';
import {
  newPasswordEpic,
  resetPasswordEmailEpic,
} from '../components/workspace/authComponents/resetPassword/redux/epic';
import { initialClientEpic } from '../components/website/webapp/redux/epic';
import { getUserEpic } from '../api/users/getUser/redux/epic';
import getUserReducer from '../api/users/getUser/redux/reducer';
import initialClientReducer from '../components/website/webapp/redux/reducer';
import updateUserReducer from '../api/users/updateUser/redux/reducer';
import { updateUserEpic } from '../api/users/updateUser/redux/epic';
import listUserReducer from '../api/users/listUsers/redux/reducer';
import { listUsersEpic } from '../api/users/listUsers/redux/epic';
import { listDevicesEpic } from '../api/devices/listDevices/redux/epic';
import listDevicesReducer from '../api/devices/listDevices/redux/reducer';
import createDeviceReducer from '../api/devices/createDevice/redux/reducer';
import { createDeviceEpic } from '../api/devices/createDevice/redux/epic';
import mapLocationReducer from '../map/redux/reducer';
import { getDeviceEpic } from '../api/devices/getDevices/redux/epic';
import getDeviceReducer from '../api/devices/getDevices/redux/reducer';
import updateDeviceReducer from '../api/devices/updateDevices/redux/reducer';
import { updateDeviceEpic } from '../api/devices/updateDevices/redux/epic';
import approveDeviceReducer from '../api/devices/approveDevice/redux/reducer';
import { approveDeviceEpic } from '../api/devices/approveDevice/redux/epic';
import deleteDeviceReducer from '../api/devices/deleteDevices/redux/reducer';
import { deleteDeviceEpic } from '../api/devices/deleteDevices/redux/epic';
import searchDeviceReducer from '../api/devices/searchDevices/redux/reducer';
import { searchDeviceEpic } from '../api/devices/searchDevices/redux/epic';
import notificationReducer from '../components/addComponents/redux/reducer';

const rootEpic = combineEpics(
  singInEpic,
  registrationCridentialsEpic,
  registrationCodeConfirmationEpic,
  resetPasswordEmailEpic,
  newPasswordEpic,
  initialClientEpic,
  getUserEpic,
  updateUserEpic,
  listUsersEpic,
  listDevicesEpic,
  createDeviceEpic,
  getDeviceEpic,
  updateDeviceEpic,
  approveDeviceEpic,
  deleteDeviceEpic,
  searchDeviceEpic,
);

const rootReducer = combineReducers({
  primiaryInterfaceReducer,
  singInreducer,
  registrationReducer,
  passwordResetReducer,
  initialClientReducer,
  getUserReducer,
  updateUserReducer,
  listUserReducer,
  mapLocationReducer,
  listDevicesReducer,
  createDeviceReducer,
  getDeviceReducer,
  updateDeviceReducer,
  approveDeviceReducer,
  deleteDeviceReducer,
  searchDeviceReducer,
  notificationReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
