import { Device } from '../../types';

export enum SearchDeviceActionTypes {
  SEARCH_DEVICE_REQUEST = 'SEARCH_DEVICE_REQUEST',
  SEARCH_DEVICE_SUCCESS = 'SEARCH_DEVICE_SUCCESS',
  SEARCH_DEVICE_ERROR = 'SEARCH_DEVICE_ERROR',

  OPEN_SEARCH_DROP_DOWN = 'OPEN_SEARCH_DROP_DOWN',
  CLOSE_SEARCH_DROP_DOWN = 'CLOSE_SEARCH_DROP_DOWN',

  SET_IS_LOADING = 'SET_IS_LOADING',
  ZOOM_TO_DEVICE = 'ZOOM_TO_DEVICE',
}

export interface SearchDeviceRequest {
  type: SearchDeviceActionTypes.SEARCH_DEVICE_REQUEST;
  searchDeviceQuery: string;
}

export interface SearchDeviceSuccess {
  type: SearchDeviceActionTypes.SEARCH_DEVICE_SUCCESS;
  searchDevices: Device[];
}

export interface SearchDeviceError {
  type: SearchDeviceActionTypes.SEARCH_DEVICE_ERROR;
  error: Error;
}

export interface OpenSearchDropDown {
  type: SearchDeviceActionTypes.OPEN_SEARCH_DROP_DOWN;
}

export interface CloseSearchDropDown {
  type: SearchDeviceActionTypes.CLOSE_SEARCH_DROP_DOWN;
}

export interface SetIsLoading {
  type: SearchDeviceActionTypes.SET_IS_LOADING;
}

export interface ZoomToDevice {
  type: SearchDeviceActionTypes.ZOOM_TO_DEVICE;
  selectedDevice: Device | null;
}

export type SearchDeviceActions =
  | SearchDeviceRequest
  | SearchDeviceSuccess
  | SearchDeviceError
  | CloseSearchDropDown
  | OpenSearchDropDown
  | SetIsLoading
  | ZoomToDevice;

export function searchDeviceRequest(searchDeviceQuery: string): SearchDeviceRequest {
  return {
    type: SearchDeviceActionTypes.SEARCH_DEVICE_REQUEST,
    searchDeviceQuery,
  };
}

export function searchDeviceSuccess(searchDevices: Device[]): SearchDeviceSuccess {
  return {
    type: SearchDeviceActionTypes.SEARCH_DEVICE_SUCCESS,
    searchDevices,
  };
}

export function searchDeviceError(error: Error): SearchDeviceError {
  return {
    type: SearchDeviceActionTypes.SEARCH_DEVICE_ERROR,
    error,
  };
}

export function openSearchDropDown(): OpenSearchDropDown {
  return {
    type: SearchDeviceActionTypes.OPEN_SEARCH_DROP_DOWN,
  };
}

export function closeSearchDropDown(): CloseSearchDropDown {
  return {
    type: SearchDeviceActionTypes.CLOSE_SEARCH_DROP_DOWN,
  };
}

export function setIsLoading(): SetIsLoading {
  return {
    type: SearchDeviceActionTypes.SET_IS_LOADING,
  };
}

export function zoomToDevice(selectedDevice: Device | null): ZoomToDevice {
  return {
    type: SearchDeviceActionTypes.ZOOM_TO_DEVICE,
    selectedDevice,
  };
}
