import update from 'immutability-helper';
import { GetDeviceActionTypes, GetDeviceActions } from './action';

enum DeviceStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export interface GetDeviceState {
  loading: boolean;
  error: null | Error;
  getDeviceId: string;
  getDeviceData: {
    address: string;
    approvalsCount: number;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    notes: string;
    registrationDate: string;
    organization: string;
    status: DeviceStatus;
    user: {
      id: string;
    };
  };
  getDeviceModal: boolean;
  getDeviceSuccess: boolean;
}

export const initialGetDeviceState: GetDeviceState = {
  loading: false,
  error: undefined,
  getDeviceData: null,
  getDeviceSuccess: false,
  getDeviceId: '',
  getDeviceModal: false,
};

export default function getDeviceReducer(state = initialGetDeviceState, action: GetDeviceActions) {
  switch (action.type) {
    case GetDeviceActionTypes.GET_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
        getDeviceId: { $set: action.getDeviceId },
      });
    case GetDeviceActionTypes.GET_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        getDeviceData: { $set: action.getDeviceData },
      });
    case GetDeviceActionTypes.GET_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case GetDeviceActionTypes.OPEN_GET_DEVICE_MODAL:
      return update(state, {
        getDeviceModal: { $set: true },
      });
    case GetDeviceActionTypes.CLOSE_GET_DEVICE_MODAL:
      return update(state, {
        getDeviceModal: { $set: false },
      });
    default:
      return state;
  }
}
