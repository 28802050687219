import { User } from '../../types';

export enum ListUsersActionTypes {
  LIST_USER_REQUEST = 'LIST_USER_REQUEST',
  LIST_USER_SUCCESS = 'LIST_USER_SUCCESS',
  LIST_USER_ERROR = 'LIST_USER_ERROR',

  OPEN_LEADER_BOARD = 'OPEN_LEADER_BOARD',
  CLOSE_LEADER_BOARD = 'CLOSE_LEADER_BOARD',
}

export interface UserFilter {
  users: User[];
  total: number;
}

export interface ListUserRequest {
  type: ListUsersActionTypes.LIST_USER_REQUEST;
  userFilter: UserFilter;
}

export interface ListUserSuccess {
  type: ListUsersActionTypes.LIST_USER_SUCCESS;
  userFilter: UserFilter;
}

export interface ListUserError {
  type: ListUsersActionTypes.LIST_USER_ERROR;
  error: Error;
}

export interface OpenLeaderBoard {
  type: ListUsersActionTypes.OPEN_LEADER_BOARD;
}

export interface CloseLeaderBoard {
  type: ListUsersActionTypes.CLOSE_LEADER_BOARD;
}

export type ListUserActions = ListUserRequest | ListUserSuccess | ListUserError | OpenLeaderBoard | CloseLeaderBoard;

export function listUserRequest(userFilter: UserFilter): ListUserRequest {
  return {
    type: ListUsersActionTypes.LIST_USER_REQUEST,
    userFilter,
  };
}

export function listUserSuccess(userFilter: UserFilter): ListUserSuccess {
  return {
    type: ListUsersActionTypes.LIST_USER_SUCCESS,
    userFilter,
  };
}

export function listUserError(error: Error): ListUserError {
  return {
    type: ListUsersActionTypes.LIST_USER_ERROR,
    error,
  };
}

export function openLeaderBoard(): OpenLeaderBoard {
  return {
    type: ListUsersActionTypes.OPEN_LEADER_BOARD,
  };
}

export function closeLeaderBoard(): CloseLeaderBoard {
  return {
    type: ListUsersActionTypes.CLOSE_LEADER_BOARD,
  };
}
