import { UserType } from '../../types';
import { UpdateUserActionTypes, UpdateUserActions } from './action';
import update from 'immutability-helper';

export interface UpdateUserState {
  loading: boolean;
  userName: string;
  phoneNumber: string;
  updateUserFields: any;
  successfulUpdatedUserFields: {
    id: string;
    username: string;
    userType: UserType;
    phoneNumber: string;
    avatar: string;
  };
  updateUserFailed: Error;
  profileModal: boolean;
}

export const initialUpdateUserState: UpdateUserState = {
  loading: false,
  userName: '',
  phoneNumber: '',
  successfulUpdatedUserFields: null,
  updateUserFailed: null,
  updateUserFields: null,
  profileModal: false,
};

export default function updateUserReducer(state = initialUpdateUserState, action: UpdateUserActions) {
  switch (action.type) {
    case UpdateUserActionTypes.UPDATE_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case UpdateUserActionTypes.UPDATE_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        successfulUpdatedUserFields: { $set: action.successfulUpdatedUserFields },
        profileModal: { $set: false },
      });
    case UpdateUserActionTypes.UPDATE_USER_ERROR:
      return update(state, {
        loading: { $set: false },
        updateUserFailed: { $set: action.updateUserFailed },
      });
    case UpdateUserActionTypes.OPEN_PROFILE:
      return update(state, {
        profileModal: { $set: true },
      });
    case UpdateUserActionTypes.CLOSE_PROFILE:
      return update(state, {
        profileModal: { $set: false },
      });
    default:
      return state;
  }
}
