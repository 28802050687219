import { Observable, filter, switchMap, debounceTime } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { listDevicesQuery } from '../../listDevices/query';
import {
  SearchDeviceActionTypes,
  SearchDeviceActions,
  SearchDeviceRequest,
  searchDeviceSuccess,
  searchDeviceError,
} from './action';

export function searchDeviceEpic(action$): Observable<SearchDeviceActions> {
  return action$.pipe(
    filter((action: SearchDeviceActions) => action.type === SearchDeviceActionTypes.SEARCH_DEVICE_REQUEST),
    debounceTime(2000),
    switchMap(({ searchDeviceQuery }: SearchDeviceRequest) =>
      searchDevices(searchDeviceQuery).then(searchDeviceSuccess).catch(searchDeviceError),
    ),
  );
}

export async function searchDevices(query: string): Promise<any> {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: {
      listDevices: { devices },
    },
  } = await graphQLClient.query({
    query: listDevicesQuery,
    variables: {
      input: {
        query,
      },
    },
  });

  return devices;
}
