export enum InitialClientActionTypes {
  INITIAL_CLIENT_REQUEST = 'INITIAL_CLIENT_REQUEST',
  INITIAL_CLIENT_SUCCESS = 'INITIAL_CLIENT_SUCCESS',
  INITIAL_CLIENT_ERROR = 'INITIAL_CLIENT_ERROR',
}

export interface NotificationData {
  notificationBg: string;
  notificationMessageColor: string;
  notificationMessage: string;
}

export interface InitialClientRequest {
  type: InitialClientActionTypes.INITIAL_CLIENT_REQUEST;
}

export interface InitialClientSuccess {
  type: InitialClientActionTypes.INITIAL_CLIENT_SUCCESS;
  session: any;
}

export interface InitialClientError {
  type: InitialClientActionTypes.INITIAL_CLIENT_ERROR;
  initialError: Error;
}

export type InitialClientActions = InitialClientRequest | InitialClientSuccess | InitialClientError;

export function initialClientRequest(): InitialClientRequest {
  return {
    type: InitialClientActionTypes.INITIAL_CLIENT_REQUEST,
  };
}

export function initialClientSuccess(session: any): InitialClientSuccess {
  return {
    type: InitialClientActionTypes.INITIAL_CLIENT_SUCCESS,
    session,
  };
}

export function initialClientError(initialError: Error): InitialClientError {
  return {
    type: InitialClientActionTypes.INITIAL_CLIENT_ERROR,
    initialError,
  };
}