export enum MapLocationActionsTypes {
  SET_SAVE_USER_LOCATION = 'SET_SAVE_USER_LOCATION',
  SET_SAVE_DEVICE_LOCATION = 'SET_SAVE_DEVICE_LOCATION',

  SET_USER_LOCATION_ERROR = 'SET_USER_LOCATION_ERROR',
  SET_DEVICE_LOCATION_ERROR = 'SET_DEVICE_LOCATION_ERROR',

  SET_ACTIVE_MARKER = 'SET_ACTIVE_MARKER',

  OPEN_MAP_CART = 'OPEN_MAP_CART',
  CLOSE_MAP_CART = 'CLOSE_MAP_CART',
}

export interface SetSaveUserLocation {
  type: MapLocationActionsTypes.SET_SAVE_USER_LOCATION;
  userLocation: {
    lat: number;
    lon: number;
  };
}

export interface SetSaveDeviceLocation {
  type: MapLocationActionsTypes.SET_SAVE_DEVICE_LOCATION;
  deviceLocation: {
    coordinats: {
      lat: number;
      lon: number;
    };
    address: object;
  };
}

export interface SetUserLocationError {
  type: MapLocationActionsTypes.SET_USER_LOCATION_ERROR;
  userLocError: string;
}

export interface SetDeviceLocationError {
  type: MapLocationActionsTypes.SET_DEVICE_LOCATION_ERROR;
  deviceLocError: string;
}

export interface SetActiveMarker {
  type: MapLocationActionsTypes.SET_ACTIVE_MARKER;
  isActiveMarker: boolean;
  activeDeviceId: string;
}

export interface OpenMapCart {
  type: MapLocationActionsTypes.OPEN_MAP_CART;
}

export interface CloseMapCart {
  type: MapLocationActionsTypes.CLOSE_MAP_CART;
}

export function setSaveUserLocation(userLocation: { lat: number; lon: number }): SetSaveUserLocation {
  return {
    type: MapLocationActionsTypes.SET_SAVE_USER_LOCATION,
    userLocation,
  };
}

export function setUserLocationError(userLocError: string): SetUserLocationError {
  return {
    type: MapLocationActionsTypes.SET_USER_LOCATION_ERROR,
    userLocError,
  };
}

export function setSaveDeviceLocation(deviceLocation: {
  coordinats: {
    lat: number;
    lon: number;
  };
  address: object;
}): SetSaveDeviceLocation {
  return {
    type: MapLocationActionsTypes.SET_SAVE_DEVICE_LOCATION,
    deviceLocation,
  };
}

export function setDeviceLocationError(deviceLocError: string): SetDeviceLocationError {
  return {
    type: MapLocationActionsTypes.SET_DEVICE_LOCATION_ERROR,
    deviceLocError,
  };
}

export function setActiveMarker(isActiveMarker: boolean, activeDeviceId: string): SetActiveMarker {
  return {
    type: MapLocationActionsTypes.SET_ACTIVE_MARKER,
    isActiveMarker,
    activeDeviceId,
  };
}

export function openMapCart(): OpenMapCart {
  return {
    type: MapLocationActionsTypes.OPEN_MAP_CART,
  };
}

export function closeMapCart(): CloseMapCart {
  return {
    type: MapLocationActionsTypes.CLOSE_MAP_CART,
  };
}

export type MapLocationActions =
  | SetSaveUserLocation
  | SetUserLocationError
  | SetSaveDeviceLocation
  | OpenMapCart
  | CloseMapCart
  | SetActiveMarker
  | SetDeviceLocationError;
