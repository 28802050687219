export enum UpdateUserActionTypes {
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',

  OPEN_PROFILE = 'OPEN_PROFILE',
  CLOSE_PROFILE = 'CLOSE_PROFILE',
}

export interface UpdateUserRequest {
  type: UpdateUserActionTypes.UPDATE_USER_REQUEST;
  updateUserFields: any;
}

export interface UpdateUserSuccess {
  type: UpdateUserActionTypes.UPDATE_USER_SUCCESS;
  successfulUpdatedUserFields: any;
}

export interface UpdateUserError {
  type: UpdateUserActionTypes.UPDATE_USER_ERROR;
  updateUserFailed: Error;
}

export interface OpenProfile {
  type: UpdateUserActionTypes.OPEN_PROFILE;
}

export interface CloseProfile {
  type: UpdateUserActionTypes.CLOSE_PROFILE;
}

export function updateUserRequest(updateUserFields: any): UpdateUserRequest {
  return {
    type: UpdateUserActionTypes.UPDATE_USER_REQUEST,
    updateUserFields,
  };
}

export function updateUserSuccess(successfulUpdatedUserFields: any): UpdateUserSuccess {
  return {
    type: UpdateUserActionTypes.UPDATE_USER_SUCCESS,
    successfulUpdatedUserFields,
  };
}

export function updateUserError(updateUserFailed: Error): UpdateUserError {
  return {
    type: UpdateUserActionTypes.UPDATE_USER_ERROR,
    updateUserFailed,
  };
}

export function openProfile(): OpenProfile {
  return {
    type: UpdateUserActionTypes.OPEN_PROFILE,
  };
}

export function closeProfile(): CloseProfile {
  return {
    type: UpdateUserActionTypes.CLOSE_PROFILE,
  };
}

export type UpdateUserActions =
  | UpdateUserRequest
  | UpdateUserRequest
  | UpdateUserSuccess
  | UpdateUserError
  | OpenProfile
  | CloseProfile;
