import update from 'immutability-helper';
import { UpdateDeviceActionTypes } from './action';
import { DeviceType } from '../../types';
import { DeleteDeviceActionTypes } from '../../deleteDevices/redux/action';
import { AppActions } from '../../../../helpers/appActionTypes';

export interface UpdateDeviceState {
  loading: boolean;
  updateDeviceRequestFields: {
    address: string;
    deviceType: DeviceType;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    notes: string;
  };
  updateDeviceSuccessFields: {
    address: string;
    deviceType: DeviceType;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    notes: string;
    organization: string;
  };
  updateDeviceError: Error;
  updateDeviceModal: boolean;
}

export const initialUpdateDeviceState: UpdateDeviceState = {
  loading: false,
  updateDeviceRequestFields: null,
  updateDeviceSuccessFields: null,
  updateDeviceError: null,
  updateDeviceModal: false,
};

export default function updateDeviceReducer(state = initialUpdateDeviceState, action: AppActions) {
  switch (action.type) {
    case UpdateDeviceActionTypes.UPDATE_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
        updateDeviceRequestFields: { $set: action.updateDeviceRequestFields },
      });
    case UpdateDeviceActionTypes.UPDATE_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        updateDeviceSuccessFields: { $set: action.updateDeviceSuccessFields },
      });
    case UpdateDeviceActionTypes.UPDATE_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        updateDeviceError: { $set: action.error },
      });
    case UpdateDeviceActionTypes.OPEN_UPDATE_DEVICE_MODAL:
      return update(state, {
        updateDeviceModal: { $set: true },
      });
    case UpdateDeviceActionTypes.CLOSE_UPDATE_DEVICE_MODAL:
      return update(state, {
        updateDeviceModal: { $set: false },
      });
    case DeleteDeviceActionTypes.DELETE_DEVICE_SUCCESS:
      return update(state, {
        updateDeviceModal: { $set: false },
      });
    case UpdateDeviceActionTypes.UPDATE_DEVICE_DEFAULT_STATE:
      return state;
    default:
      return state;
  }
}
