import { Observable } from 'redux';
import { filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { updateUserMutation } from '../query';
import {
  UpdateUserActionTypes,
  UpdateUserActions,
  UpdateUserRequest,
  updateUserError,
  updateUserSuccess,
} from './action';

export function updateUserEpic(action$): Observable<UpdateUserActions> {
  return action$.pipe(
    filter((action: UpdateUserActions) => {
      return action.type === UpdateUserActionTypes.UPDATE_USER_REQUEST;
    }),
    switchMap(({ updateUserFields }: UpdateUserRequest) =>
      updateUser(updateUserFields).then(updateUserSuccess).catch(updateUserError),
    ),
  );
}

export async function updateUser(updatedUserFields: any) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { updateUser: user },
  } = await graphQLClient.mutate({
    mutation: updateUserMutation,
    variables: { input: updatedUserFields },
  });

  return user;
}
