import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { listUsersQuery } from '../query';
import { ListUsersActionTypes, ListUserRequest, listUserSuccess, listUserError } from './action';
import { AppActions } from '../../../../helpers/appActionTypes';

export function listUsersEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: AppActions) => action.type === ListUsersActionTypes.LIST_USER_REQUEST),
    switchMap(({ userFilter }: ListUserRequest) => listUsers(userFilter).then(listUserSuccess).catch(listUserError)),
  );
}

export async function listUsers({ filter }: any): Promise<any> {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: {
      listUsers: { users, total },
    },
  } = await graphQLClient.query({
    query: listUsersQuery,
    variables: {
      input: {
        filter,
      },
    },
  });

  return { total, users };
}
