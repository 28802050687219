import update from 'immutability-helper';
import { InitialClientActionTypes, InitialClientActions } from './action';

export interface InitialClientState {
  initialClientLoading: boolean;
  user: boolean;
  session?: any;
  id: string;
  email: string;
  initialError: Error;
  initialClientSuccess: boolean;
}

export const initialClientState: InitialClientState = {
  initialClientLoading: false,
  user: false,
  id: null,
  email: null,
  initialError: null,
  initialClientSuccess: false,
};

export default function initialClientReducer(state = initialClientState, action: InitialClientActions) {
  switch (action.type) {
    case InitialClientActionTypes.INITIAL_CLIENT_REQUEST:
      return update(state, {
        initialClientLoading: { $set: true },
        user: { $set: false },
      });
    case InitialClientActionTypes.INITIAL_CLIENT_SUCCESS:
      const emailAttribute = action.session?.find((attr) => attr.Name === 'email');
      const emailValue = emailAttribute ? emailAttribute.Value : null;
      const valueAttribute = action.session?.find((attr) => attr.Name === 'userId');
      const value = valueAttribute ? valueAttribute.Value : null;

      return update(state, {
        session: { $set: action.session },
        email: { $set: emailValue },
        user: { $set: true },
        initialClientLoading: { $set: false },
        id: { $set: value },
        initialClientSuccess: { $set: true },
      });
    case InitialClientActionTypes.INITIAL_CLIENT_ERROR:
      return update(state, {
        user: { $set: false },
        initialClientLoading: { $set: false },
        initialError: { $set: action.initialError },
      });
    default:
      return state;
  }
}
